import React, { useState } from 'react';
import classnames from 'classnames';
import { Row, Col } from 'antd';

import StoryCard from './story/StoryCard';
import DynamicGrid from './common/DynamicGrid';

import useWindowDimensions from '../helpers/useWindowDimensions';
import {mapGridColBySize} from '../helpers/NumberFormat';
import NoStretchDiv from '../helpers/NoStretchDiv';
import InfiniteList from './common/InfiniteList';

const Home = () => {
    const { width } = useWindowDimensions();
    const [ orderBy, setOrderBy ] = useState('likes');

    var skeletonCards = [];
    for(var i = 0; i < 8; i++){
        skeletonCards.push(<StoryCard skeleton={true} />)
    }

    return (
        <div>
            <div style={{width: '100%', height: 'auto', borderBottom: '1px solid rgba(200,200,200,0.25)'}}>
                <Row>
                    <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                        <div style={{textAlign: 'center', paddingTop: '5px'}}>
                            <h1>Download the fablment app!</h1>
                            <p>Read on the go</p>
                        </div>
                    </Col>
                    <Col xs={24} sm={14} md={14} lg={14} xl={14}>
                        <div style={{textAlign: 'center', paddingTop: '5px'}}>
                            <a 
                                href='https://apps.apple.com/us/app/fablement/id1536568152?itsct=apps_box&amp;itscg=30200'
                                style={{display: 'inline-block'}}
                            >
                                <img 
                                    src="/appstore.svg" 
                                    alt="Download on the App Store" 
                                    height="60"
                                    style={{padding: '7%'}}
                                />
                            </a>
                            <a
                                href='https://play.google.com/store/apps/details?id=com.fablement.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                                style={{display: 'inline-block'}}
                            >
                                <img 
                                    src='/playstore.png'
                                    alt='Get it on Google Play' 
                                    height="60"
                                    // style={{height: '50px', width: '130px', objectFit: 'fill'}}
                                />
                            </a>
                        </div>
                    </Col>
                </Row>
            </div>
            <NoStretchDiv style={{padding: "5%", background: "none"}}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingLeft: '20%',
                        paddingRight: '20%',
                    }}
                >
                    {orderBy === 'saves' ? (
                        <a className={classnames('text-darkable', {primaryColor: orderBy === 'saves', primaryColorDarkable: orderBy === 'saves'})} 
                            onClick={()=>{orderBy === 'saves' ? setOrderBy('likes') : setOrderBy('saves')}}
                        >saves</a>
                    ) : (
                        <a className={classnames('text-darkable', {primaryColor: orderBy === 'likes', primaryColorDarkable: orderBy === 'likes'})} 
                            onClick={()=>{orderBy === 'likes' ? setOrderBy('saves') : setOrderBy('likes')}}
                        >likes</a>
                    )}

                    {orderBy === 'readAsc' ? (
                        <a className={classnames('text-darkable', {primaryColor: orderBy === 'readAsc', primaryColorDarkable: orderBy === 'readAsc'})} 
                            onClick={()=>{orderBy === 'readAsc' ? setOrderBy('readDesc') : setOrderBy('readAsc')}}
                        >reads &#8593;</a>  
                    ) : (
                        <a className={classnames('text-darkable', {primaryColor: orderBy === 'readDesc', primaryColorDarkable: orderBy === 'readDesc'})} 
                            onClick={()=>{orderBy === 'readDesc' ? setOrderBy('readAsc') : setOrderBy('readDesc')}}
                        >reads &#8595;</a>
                    )}
                    
                    {orderBy === 'titleDesc' ? (
                        <a className={classnames('text-darkable', {primaryColor: orderBy === 'titleDesc', primaryColorDarkable: orderBy === 'titleDesc'})} 
                            onClick={()=>{orderBy === 'titleDesc' ? setOrderBy('titleAsc') : setOrderBy('titleDesc')}}
                        >title &#8595;</a>  
                    ) : (
                        <a className={classnames('text-darkable', {primaryColor: orderBy === 'titleAsc', primaryColorDarkable: orderBy === 'titleAsc'})} 
                            onClick={()=>{orderBy === 'titleAsc' ? setOrderBy('titleDesc') : setOrderBy('titleAsc')}}
                        >title &#8593;</a>
                    )}

                    {orderBy === 'oldest' ? (
                        <a className={classnames('text-darkable', {primaryColor: orderBy === 'oldest', primaryColorDarkable: orderBy === 'oldest'})} 
                            onClick={()=>{orderBy === 'oldest' ? setOrderBy('newest') : setOrderBy('oldest')}}
                        >oldest</a>  
                    ) : (
                        <a className={classnames('text-darkable', {primaryColor: orderBy === 'newest', primaryColorDarkable: orderBy === 'newest'})} 
                            onClick={()=>{orderBy === 'newest' ? setOrderBy('oldest') : setOrderBy('newest')}}
                        >newest</a>
                    )}
                </div>

                <InfiniteList 
                    apiUrl={'/api/story_version/home'}
                    apiExtra={'&orderBy=' + orderBy}
                    pageSize={8}
                    loader={
                        <DynamicGrid 
                            gutter={16}
                            colCount={mapGridColBySize(width)}
                            colClassName="mobile-stretch"
                            cells={skeletonCards}
                        />
                    }
                    renderItem={(stories) => {
                        let storyCards;
                        storyCards = stories.map((story, i) => {
                            return (
                                <StoryCard story={story}/>
                            );
                        })

                        return (
                            <DynamicGrid 
                                gutter={16}
                                colCount={mapGridColBySize(width)}
                                colClassName="mobile-stretch"
                                cells={storyCards}
                            />
                        );
                    }}
                />
            </NoStretchDiv>
        </div>
    )
}

export default Home;
