import React from 'react';
import Link from 'next/link'

import Icon from '../icon/Icon';
import FeedbackModal from '../forms/FeedbackModal';
import {cleanNumberParts} from '../../helpers/NumberFormat';

export const Banner30DataSource = {
  wrapper: { className: 'banner3' },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      {
        name: 'nameEn',
        className: 'banner3-name-en',
        children: '',
      },
      {
        name: 'slogan',
        className: 'banner3-slogan',
        children: 
          <img 
            src={
                process.env.NEXT_PUBLIC_PROTOCOL_URL 
                + process.env.NEXT_PUBLIC_DOMAIN_NAME 
                + '/f.svg'
            }
            alt="fablement_f"
          />,
        // texty: true,
      },
      {
        name: 'name',
        className: 'banner3-name',
        children: 'interactive stories done easy at fablement',
      },
      { 
        name: 'button', className: 'banner3-button', 
        children: <Link href="/register"><a>register</a></Link>
      },
      {
        name: 'time',
        className: 'banner3-time',
        children: 
          <div className="clickable">
            <Icon onClick={() => {
              var x = document.getElementById('Content14_0')
              if(x)
                  x.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                      inline: 'center',
                  });
            }} name="angle-down" size={2} />
          </div>,
      },
    ],
  },
};
export const Content14DataSource = {
  wrapper: { className: 'home-page-wrapper content14-wrapper' },
  page: { className: 'home-page content14' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper primaryColor',
    children: [
      {
        name: 'title',
        children: 'who we are',
        className: 'title-h1 primaryColor',
      },
      {
        name: 'content',
        className: 'title-content content4-title-content',
        children: <div></div>,
      },
    ],
  },
  embededVideo: {
    children: <iframe 
      title="fablement_video"
      width="100%" 
      height="315" 
      src="https://www.youtube.com/embed/ueeSuiPN8Ig" 
      frameBorder="0" 
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
      allowFullScreen
    ></iframe>,
  }
};
export const Feature00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: <span>features</span> }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            // {
            //   name: 'image',
            //   className: 'content0-block-icon',
            //   children:
            //     'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            // },
            {
              name: 'icon',
              className: 'content0-block-icon',
              children: <Icon name="feather-alt" size={5} color={'rgb(172, 52, 84)'} />,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'easy-to-use',
            },
            { name: 'content', children: 'simple drag and drop web interface meaning NO downloading or programming experience required.' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            // {
            //   name: 'image',
            //   className: 'content0-block-icon',
            //   children:
            //     'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            // },
            {
              name: 'icon',
              className: 'content0-block-icon',
              children: <Icon name="calculator" size={5} color={'rgb(172, 52, 84)'} />,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'get fancy',
            },
            {
              name: 'content',
              children: 'variables, achievements, modifiers, changelog, and more! keep readers engaged and coming back for more.',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            // {
            //   name: 'image',
            //   className: 'content0-block-icon',
            //   children:
            //     'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            // },
            {
              name: 'icon',
              className: 'content0-block-icon',
              children: <Icon name="glasses" size={5} color={'rgb(172, 52, 84)'} />,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'reading',
            },
            {
              name: 'content',
              children: 'catered to you. choose your own fonts and color, save progression, and get achievements!',
            },
          ],
        },
      },
    ],
  },
};
export const Feature60DataSource = (storyCount=0, readCount=0, userCount=0) => {
  return (
    {
      wrapper: { className: 'home-page-wrapper feature6-wrapper' },
      OverPack: { className: 'home-page feature6', playScale: 0.3 },
      Carousel: {
        className: 'feature6-content',
        dots: false,
        wrapper: { className: 'feature6-content-wrapper' },
        titleWrapper: {
          className: 'feature6-title-wrapper',
          barWrapper: {
            className: 'feature6-title-bar-wrapper',
            children: { className: 'feature6-title-bar' },
          },
          title: { className: 'feature6-title' },
        },
        children: [
          {
            title: { className: 'feature6-title-text', children: 'our numbers' },
            className: 'feature6-item',
            name: 'block0',
            children: [
              {
                md: 8,
                xs: 24,
                className: 'feature6-number-wrapper',
                name: 'child0',
                number: {
                  className: 'feature6-number',
                  unit: { className: 'feature6-unit', children: cleanNumberParts(userCount).unit },
                  toText: true,
                  children: cleanNumberParts(userCount).number,
                },
                children: { className: 'feature6-text', children: <span className="text-darkable">users</span> },
              },
              {
                md: 8,
                xs: 24,
                className: 'feature6-number-wrapper',
                name: 'child1',
                number: {
                  className: 'feature6-number',
                  unit: { className: 'feature6-unit', children: cleanNumberParts(storyCount).unit },
                  toText: true,
                  children: cleanNumberParts(storyCount).number,
                },
                children: { className: 'feature6-text', children: <span className="text-darkable">stories published</span> },
              },
              {
                md: 8,
                xs: 24,
                className: 'feature6-number-wrapper',
                name: 'child2',
                number: {
                  className: 'feature6-number',
                  unit: { className: 'feature6-unit', children: cleanNumberParts(readCount).unit },
                  toText: true,
                  children: cleanNumberParts(readCount).number,
                },
                children: { className: 'feature6-text', children: <span className="text-darkable">reads</span> },
              },
            ],
          },
          // {
          //   title: { className: 'feature6-title-text', children: '服务指标' },
          //   className: 'feature6-item',
          //   name: 'block1',
          //   children: [
          //     {
          //       md: 8,
          //       xs: 24,
          //       name: 'child0',
          //       className: 'feature6-number-wrapper',
          //       number: {
          //         className: 'feature6-number',
          //         unit: { className: 'feature6-unit', children: '万' },
          //         toText: true,
          //         children: '116',
          //       },
          //       children: { className: 'feature6-text', children: '模型数据' },
          //     },
          //     {
          //       md: 8,
          //       xs: 24,
          //       name: 'child1',
          //       className: 'feature6-number-wrapper',
          //       number: {
          //         className: 'feature6-number',
          //         unit: { className: 'feature6-unit', children: '亿' },
          //         toText: true,
          //         children: '1.17',
          //       },
          //       children: { className: 'feature6-text', children: '模型迭代数量' },
          //     },
          //     {
          //       md: 8,
          //       xs: 24,
          //       name: 'child2',
          //       className: 'feature6-number-wrapper',
          //       number: {
          //         className: 'feature6-number',
          //         unit: { className: 'feature6-unit', children: '亿' },
          //         toText: true,
          //         children: '2.10',
          //       },
          //       children: { className: 'feature6-text', children: '训练样本数量' },
          //     },
          //   ],
          // },
        ],
      },
    }
  )
};
export const Teams00DataSource = {
  wrapper: { className: 'home-page-wrapper teams0-wrapper' },
  OverPack: { playScale: 0.3, className: 'home-page teams0' },
  BannerAnim: {
    className: 'banner-anim',
    children: [
      {
        name: 'elem0',
        className: 'teams0-banner-user-elem',
        titleWrapper: {
          className: 'teams0-content-wrapper',
          children: [
            {
              name: 'image',
              children:
                'https://res.cloudinary.com/didrgfkvh/image/upload/v1582508250/profilePhotos/tdamq5atuto2jjokb5ar.png',
              className: 'teams0-image',
            },
            {
              name: 'content',
              children: <span style={{color: 'white'}}>founder</span>,
              className: 'teams0-content',
            },
            { name: 'title', children: <span style={{color: 'white'}}>Sean</span>, className: 'teams0-h1' },
            {
              name: 'content2',
              children: 
                <span style={{color: 'white'}}>
                  Currently, I'm the sole developer at fablement. 
                  I started this project in 2018 with a vision of helping writers create a brand and build a following as easily as possible.
                </span>,
              className: 'teams0-content',
            },
          ],
        },
      },
      // {
      //   name: 'elem1',
      //   className: 'teams0-banner-user-elem',
      //   titleWrapper: {
      //     className: 'teams0-content-wrapper',
      //     children: [
      //       {
      //         name: 'image',
      //         children:
      //           'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*--rVR4hclJYAAAAAAAAAAABjARQnAQ',
      //         className: 'teams0-image',
      //       },
      //       {
      //         name: 'content',
      //         children:
      //           'SEE = Seeking Experience & Engineering，意为探索用户体验与工程实践，由蚂蚁金服集团每年举办 1-2 次，包括专业分享、产品展台、Workshop 等内容。',
      //         className: 'teams0-content',
      //       },
      //       { name: 'title', children: '叶秀英', className: 'teams0-h1' },
      //       {
      //         name: 'content2',
      //         children: '公司+职位 信息暂缺',
      //         className: 'teams0-content',
      //       },
      //     ],
      //   },
      // },
    ],
  },
};
export const Content120DataSource = {
  wrapper: { className: 'home-page-wrapper content12-wrapper' },
  page: { className: 'home-page content12' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      { name: 'title', children: '特别鸣谢', className: 'title-h1' },
    ],
  },
  block: {
    className: 'img-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/TFicUVisNHTOEeMYXuQF.svg',
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/hkLGkrlCEkGZeMQlnEkD.svg',
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/bqyPRSZmhvrsfJrBvASi.svg',
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/UcsyszzOabdCYDkoPPnM.svg',
          },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/kRBeaICGexAmVjqBEqgw.svg',
          },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/ftBIiyJcCHpHEioRvPsV.svg',
          },
        },
      },
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo2',
          children: 
            <Link href='/'>
                <img 
                  src={'/fablement.svg'}
                  alt="fablement"
                  height='46'
                  style={{width: '100%'}}
                />
            </Link>
        },
        childWrapper: {
          className: 'slogan center-text',
          children: [
            {
              name: 'content0',
              children: 'interactive stories made easy',
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: 'info' },
        childWrapper: {
          children: [
            { name: 'link0', children: <Link href='/about'><a>about us</a></Link>, },
            { name: 'link1', children: 
              <a>
                <FeedbackModal 
                  opener={
                    <span className="clickable link-hoverable">
                      contact us
                    </span>
                  }
                />
              </a>,
            },
            { name: 'link2', children: <a href='https://fablement.com/book/110/Fablement_Roadmap'>roadmap</a>, },
            { name: 'link3', children: <Link href='/faq'><a>faq</a></Link>, },
          ],
        },
      },
      // {
      //   name: 'block2',
      //   xs: 24,
      //   md: 6,
      //   className: 'block',
      //   title: { children: 'resources' },
      //   childWrapper: {
      //     children: [
      //       { href: '#', name: 'link0', children: 'faq' },
      //     ],
      //   },
      // },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: 'social' },
        childWrapper: {
          children: [
            { name: 'link0', children: 
              <a target="_blank" rel="noopener noreferrer" href="https://reddit.com/r/fablement">reddit</a> 
            },
            { name: 'link1', children: 
              <a target="_blank" rel="noopener noreferrer" href="https://discord.gg/TYWryQx">discord</a> 
            },
            { name: 'link2', children: 
              <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/fablement">facebook</a> 
            },
            { name: 'link3', children: 
              <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/fablement_official/">instagram</a> 
            },
            // { name: 'link4', children: 
            //   <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/real_fablement">twitter</a> 
            // },
          ],
        },
      },
      {
        name: 'block4',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: 'mobile' },
        childWrapper: {
          children: [
            { name: 'link0', children: 
              <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/fablement/id1536568152?itsct=apps_box&amp;itscg=30200">ios</a> 
            },
            { name: 'link1', children: 
              <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.fablement.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">android</a> 
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <a href="https://fablement.com">fablement</a> ©2020. All Rights
        Reserved
      </span>
    ),
  },
};
