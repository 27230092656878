import React, {forwardRef, useImperativeHandle, useEffect, useContext, useState} from "react";
import { Menu, Layout, Divider, Badge, Skeleton } from 'antd';
import Link from 'next/link'
import { useRouter } from 'next/router'
import _ from 'lodash';

import UserContext from '../../context/UserContext'
import AppContext from '../../context/AppContext'
import useWindowDimensions from '../../helpers/useWindowDimensions';
import Icon from '../icon/Icon';
import ProfileAvatar from '../common/ProfileAvatar';
import {callApi} from '../../helpers/Api';
import AutoHideScrollable from "../common/AutoHideScrollable";
import { LinkRouteLogin } from "../auth/LoginRouting";


const MySider = forwardRef((props, ref) => {
    const {loggedInUser} = useContext(UserContext);
    const {appLoaded, setSiderLoaded} = useContext(AppContext);
    const [collapseSider, setCollapseSider] = useState(true);
    const [collapseWidth, setCollapseWidth] = useState(0);
    const [followings, setFollowings] = useState([]);
    const { width } = useWindowDimensions();
    const router = useRouter()

    useImperativeHandle(ref, () => ({
        toggleCollapseSider() {
            setCollapseSider(!collapseSider)
        }
    }));

    useEffect(() => {
        if(loggedInUser) {
            callApi('/api/own/all/user_account_follow')
            .then((json) => {
                setFollowings(json)
            })
            .catch(err => {
                console.log(err)
            });
        } else {
            setFollowings([])
        }
    }, [loggedInUser])

    useEffect(() => {
        setSiderLoaded(false)
        if(props.onCollapse)
            props.onCollapse();
        setTimeout(()=>{
            setSiderLoaded(true)
        }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collapseSider])

    useEffect(() => {
        if(appLoaded) {
            if(props.showSiderExpanded) {
                if(width <= 767) {
                    setCollapseSider(true)
                    setCollapseWidth(0)
                    setSiderLoaded(true)
                } else {
                    setCollapseSider(false)
                    setCollapseWidth(80)
                    setSiderLoaded(true)
                }
            } else {
                setCollapseSider(true)
                setCollapseWidth(0)
                setSiderLoaded(true)
            }
        }
    }, [appLoaded, router.asPath, width])

    return (
        <AutoHideScrollable onHover={true} style={{marginTop: '46px'}}>
            <Layout.Sider
                theme="light"
                className="medium-darkable"
                collapsed={collapseSider}
                collapsedWidth={collapseWidth}
                style={{
                    overflowY: 'overlay',
                    overflowX: 'hidden',
                    height: 'calc(100vh - 46px)',
                    position: 'sticky',
                    left: 0,
                    top: 46,
                    transition: props.expandSiderInstantly ? 'all 0s' : 'all .2s',
                }}
            >
                { !appLoaded ? (
                    <div style={{padding: '10%'}}>
                        <Skeleton active />
                        <Skeleton active />
                    </div>
                ) : (
                    <div>
                        <Menu className="sider-menu medium-darkable" mode="inline" selectable={false}
                            style={{borderRight: 'none', paddingTop: '20px'}}
                        >
                            <Menu.Item key="0">
                                {loggedInUser ? (
                                <Link href='/profile'>
                                    <div className="text-darkable">
                                        <Icon name="user" />
                                        <span className="nav-text">{loggedInUser.username}</span>
                                    </div>
                                </Link>
                                ) : (
                                <Link href='/login'>
                                    <div className="text-darkable">
                                        <Icon name="user" />
                                        <span className="nav-text">{'Log in'}</span>
                                    </div>
                                </Link>
                                )}
                            </Menu.Item>
                            <Menu.Item key="1">
                                <Link href='/'>
                                    <div className="text-darkable">
                                        <Icon name="home" />
                                        <span className="nav-text">Home</span>
                                    </div>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="2">
                                <LinkRouteLogin redirect='/liked'>
                                    <Link href='/liked'>
                                        <div className="text-darkable">
                                            <Icon name="heart" />
                                            <span className="nav-text">Likes</span>
                                        </div>
                                    </Link>
                                </LinkRouteLogin>
                            </Menu.Item>
                            <Menu.Item key="3">
                                <LinkRouteLogin redirect='/saved'>
                                    <Link href='/saved'>
                                        <div className="text-darkable">
                                            <Icon name="bookmark" />
                                            <span className="nav-text">Saved</span>
                                        </div>
                                    </Link>
                                </LinkRouteLogin>
                            </Menu.Item>
                            <Menu.Item key="4">
                                <LinkRouteLogin redirect='/history'>
                                    <Link href='/history'>
                                        <div className="text-darkable">
                                            <Icon name="history" />
                                            <span className="nav-text">History</span>
                                        </div>
                                    </Link>
                                </LinkRouteLogin>
                            </Menu.Item>
                            <Menu.Item key="5">
                                <a href="https://www.patreon.com/fablement" target="_blank">
                                    <div className="text-darkable">
                                        <Icon name={['fab', 'patreon']} />
                                        <span className="nav-text">Donate</span>
                                    </div>
                                </a>
                            </Menu.Item>
                            {/* <Menu.Item key="6">
                                <Link href='/contest'>
                                    <div className="text-darkable">
                                        <Icon name="trophy" />
                                        <span className="nav-text">Contest 9/2020</span>
                                    </div>
                                </Link>
                            </Menu.Item> */}
                        </Menu>
                        {followings.length > 0 ? (
                            <div>
                                <Divider />
                                <h3 className="text-darkable nav-text">Following</h3>
                            </div>
                        ) : null}
                        <Menu className="sider-menu medium-darkable" mode="inline" selectable={false}>
                            {followings.map((following, index) => {
                                return (
                                    <Menu.Item key={following.username} onClick={() => {
                                        callApi('/api/own/update_read/user_account_follow/' + following.username)
                                        .then((json) => {
                                            var temp = _.cloneDeep(followings);
                                            temp[index].last_checked = new Date();
                                            setFollowings(temp);
                                        })
                                        .catch(err => {
                                            console.log(err)
                                        });
                                    }}>
                                        <Link href={`/profile/${following.username}`}>
                                            <div className="text-darkable">
                                                <Badge dot={following.publish_date > following.last_checked ? true : false}>
                                                    <ProfileAvatar avatarUrl={following.profile_picture} size={22} alt={following.username} />
                                                </Badge>
                                                <span className="nav-text">
                                                    {following.username}
                                                </span>
                                            </div>
                                        </Link>
                                    </Menu.Item>
                                );
                            })}
                        </Menu>
                    </div>
                )}
            </Layout.Sider>
        </AutoHideScrollable>
    )
})

export default MySider;