import React, {useState, useContext, useEffect} from 'react';
import {Divider, message, Button, Tooltip} from 'antd';

import UserContext from '../../context/UserContext';
import { cleanNumber } from '../../helpers/NumberFormat';
import CommonButton from './CommonButton';
import Icon from '../icon/Icon';
import { LinkRouteLogin } from '../auth/LoginRouting';

const EnabledButton = (props) => {
    const {loggedInUser} = useContext(UserContext);
    const [enabled, setEnabled] = useState(false);
    const [count, setCount] = useState(0);

    useEffect(() => {
        setEnabled(props.enabled)
    }, [props.enabled])

    useEffect(() => {
        if(props.getInitialEnabled)
            props.getInitialEnabled(setEnabled)
        props.getInitialCount(setCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(enabled && count === 0)
            setCount(count + 1)
    }, [enabled, count])


    const onClick = (e) => {
        if(loggedInUser) {
            e.preventDefault();
            enabled ? setCount(count - 1) : setCount(count + 1);
            try {
                props.handleEnabled(!enabled, setEnabled);
            } catch (e) {
                message.error('We ran into an issue. Please try again later or leave feedback!')
            }
        }
    }

    const Outside = ({children}) => {
        if(props.outsideButton) {
            return(
                <Tooltip
                    title={props.tooltipTitle}
                    placement={props.tooltipPlacement}
                >
                    <Button 
                        block 
                        size="large"
                        className="medium-darkable" 
                        onClick={onClick}
                    >
                        {children}
                    </Button>
                </Tooltip>
            );
        } else {
            return (
                <div className="light-text" onClick={onClick}>
                    {children}
                </div>
            );
        }
    }

    const Inside = () => {
        if(props.outsideButton) {
            return(
                <Icon 
                    name={enabled ? props.enabledIcon : (props.unenabledIconColor || props.enabledIcon)}
                    color={enabled ? props.enabledIconColor : (props?.unenabledIconColor || "")}
                />
            );
        } else {
            if(loggedInUser)
                return (
                    <CommonButton 
                        tooltipTitle={props.tooltipTitle}
                        tooltipPlacement={props.tooltipPlacement}
                        className="rde-action-btn light-text"
                        shape="circle"
                        icon={enabled ? props.enabledIcon : (props.unenabledIconColor || props.enabledIcon)}
                        iconColor={enabled ? props.enabledIconColor : (props?.unenabledIconColor || "")}
                    />
                );
            else
                return (
                    <CommonButton 
                        tooltipTitle={props.tooltipTitle}
                        className="rde-action-btn light-text"
                        shape="circle"
                        icon={props.unenabledIconColor || props.enabledIcon}
                        iconColor={props?.unenabledIconColor || ""}
                    />
                );
        }
    }

    const Counter = () => {
        return (
            props.showCount ? (
                <small>
                    {cleanNumber(count)}
                    {props.showDivider ? (
                        <Divider type="vertical"/>
                    ) : null}
                </small>
            ) : null
        );
    }

    return (
        <LinkRouteLogin>
            <Outside>
                <Counter />
                <Inside />
            </Outside>
        </LinkRouteLogin>
    )
}

export default EnabledButton;