import React from 'react';
import { Row, Col } from 'antd';

const DynamicGrid = ({gutter, colCount, cells, colClassName}) => {
    const rows = [];
    for (let i = 0; i < cells.length; i+=colCount) {
        var temparray = cells.slice(i,i+colCount);
        rows.push(
            <Row gutter={gutter} key={i} style={{margin: '10px'}}>
                {temparray.map((cell, i) => {
                    return (
                    <Col className={colClassName} key={i.toString()} span={24 / colCount}>
                        {cell}
                    </Col>
                    )
                })}
            </Row>
        )
    }

    return (
        rows
    );
}

export default DynamicGrid;