const cleanNumber = (number) => {
    if(number === null)
        return 0;
    else if(number >= 1000000000) // 1B
        return (number-(number%100000000))/1000000000 + 'B';
    else if(number >= 10000000) // 10M
        return (number-(number%1000000))/1000000 + 'M';
    else if(number >= 1000000) // 1M
        // mod 1 less digits to show x.y M
        return (number-(number%100000))/1000000 + 'M';
    else if(number >= 10000) // 10K+
        return (number-(number%1000))/1000 + 'K';
    else if(number >= 1000) // 1K +
        return (number-(number%100))/1000 + 'K';
    else 
        return number;
};

const cleanNumberParts = (number) => {
    if(number >= 1000000000) // 1B
        return {
            number: (number-(number%100000000))/1000000000, 
            unit: 'B'
        };
    else if(number >= 10000000) // 10M
        return {
            number: (number-(number%1000000))/1000000,
            unit: 'M'
        };
    else if(number >= 1000000) // 1M
        // mod 1 less digits to show x.y M
        return {
            number: (number-(number%100000))/1000000,
            unit: 'M'
        };
    else if(number >= 10000) // 10K+
        return {
            number: (number-(number%1000))/1000,
            unit: 'K'
        };
    else if(number >= 1000) // 1K +
        return {
            number: (number-(number%100))/1000,
            unit: 'K'
        };
    else 
        return {number, unit: ''};
};


const mapGridColBySize = (width) => {
    if(width <= 1000)
        return 2
    else if(width <= 1200)
        return 3
    // else if (width <= 1920)
        // return 4
    else 
        return 4
}

const wordCountDiffMin = (wordCountFirst, wordCountSecond) => {
    return (wordCountFirst - wordCountSecond < 300)
}

const wordCountToMin = (wordCount) => {
    return Math.ceil(wordCount / 300);
}

export {
    cleanNumber, 
    cleanNumberParts, 
    mapGridColBySize,
    wordCountToMin,
    wordCountDiffMin,
}