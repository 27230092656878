import React from 'react';
import { useSWRInfinite } from "swr";
import InfiniteScroll from 'react-infinite-scroll-component';

const InfiniteList = ({
    children,
    apiUrl, 
    apiKey=null, 
    pageSize=10, 
    renderItem, 
    keyExtractor, 
    emptyText, 
    loadingText, 
    endText, 
    loadText, 
    styles={}, 
    apiExtra, 
    ...infiniteScrollProps 
}) => {
    const { data, error, mutate, size, setSize, isValidating, revalidate } = useSWRInfinite(
        index => [`${apiUrl}?per_page=${pageSize}&page=${index + 1}${apiExtra}`, apiKey]
    );

    const allData = data ? [].concat(...data) : [];
    const isLoadingInitialData = !data && !error;
    const isLoadingMore =
        isLoadingInitialData ||
        (size > 0 && data && typeof data[size - 1] === "undefined");
    const isEmpty = data?.[0]?.length === 0;
    const isReachingEnd =
        isEmpty || (data && data[data.length - 1]?.length < pageSize);
    const isRefreshing = isValidating && data && data.length === size;

    return (
        <InfiniteScroll
            dataLength={allData.length}
            next={()=>{
                if(!isReachingEnd && !isRefreshing) {
                    setSize(size + 1);
                }
            }}
            hasMore={!isReachingEnd}
            loader={<h4 style={{textAlign: 'center'}}>Loading...</h4>}
            endMessage={
                <p style={{textAlign: 'center', margin: 20}}>
                    {isEmpty ? emptyText || 'no items to show' : (
                        isLoadingMore
                            ? loadingText || "loading..."
                            : isReachingEnd
                                ? endText || "no more to show"
                                : loadText || "load more"
                          
                    )}
                </p>
            }
            {...infiniteScrollProps}
        >
            {renderItem(allData)}
        </InfiniteScroll>
    );
}

export default InfiniteList;