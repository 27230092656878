import React, {useEffect, useContext} from "react";
import { Button, Menu, Tooltip, Dropdown, Badge, Row, Col } from 'antd';
import Link from 'next/link'
import { useRouter } from 'next/router'
import useDarkMode from 'use-dark-mode';

import UserContext from '../../context/UserContext'
import AppContext from '../../context/AppContext'
import Icon from '../icon/Icon';
import ProfileAvatar from '../common/ProfileAvatar';
// import DropdownNotification from '../notifications/DropDownNotifications';
import {callApi} from '../../helpers/Api';

// import './Navbar.css';

const Navbar = (props) => {
    const {loggedInUser, handleLogout, setLoggedInUser} = useContext(UserContext);
    const {appLoaded} = useContext(AppContext);
    const darkMode = useDarkMode();
    const router = useRouter()
    // const systemDefault = darkMode.value;

    useEffect(() => {
        if(loggedInUser) {
            if(loggedInUser.dark_mode)
                darkMode.enable();
            else
                darkMode.disable();
        }
    // dependency must be just loggedInUser. If adding darkMode it will run on switch
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedInUser]);

    const handleChangeViewMode = () => {
        if(loggedInUser) {
            callApi('/api/own/user_account', {
                method: 'PUT',
                body: JSON.stringify({dark_mode: !darkMode.value}, null, 2)
            })
            .then(res => {
                if(res) {
                    darkMode.toggle();
                    setLoggedInUser(Object.assign({}, loggedInUser, {dark_mode: !loggedInUser.dark_mode}))
                }
            })
            .catch(err => {console.log(err)});
        }
    }

    return (
        <nav className="menu medium-darkable" style={{position: 'fixed'}}>
            <div className="menu_container">
                <div className="clickable text-darkable sider-menu-bars" style={{paddingRight: '10px', margin: 'auto'}}>
                    <Icon name="bars" onClick={()=>{props.toggleCollapseSider()}}/>
                </div>
                <div className="menu_item">
                    <div className="menu__logo clickable" style={{padding: 0, marginLeft: '10px'}}>
                        <Link href='/'>
                            <img src="/fablement.svg" alt="fablement" className="logo" />
                        </Link>
                    </div>
                </div>
                <div className="menu_item_center">
                    <div className="mobile-hide">
                        {/* <Input.Search
                            placeholder="Search"
                            onSearch={value => console.log(value)}
                            style={{ width: '80%', marginLeft: '10%', padding: '7px'}}
                        /> */}
                    </div>
                </div>
                <div className="menu_item">
                        {loggedInUser ? (
                            <Row justify="space-between" align="middle" style={{height: '100%', margin: 0, textAlign: 'right'}}>
                                <Col span={24}>
                                    <Row align="bottom">
                                        <Col flex="auto">
                                            <Link href={'/notifications'}>
                                                <a>
                                                    <Badge 
                                                        style={{
                                                            minWidth: '15px', 
                                                            maxHeight: '15px',
                                                            padding: '0 6px', 
                                                            fontSize: '10px', 
                                                            fontWeight: 'bolder',
                                                            lineHeight: '15px',
                                                            fontFamily: 'arial',
                                                        }}
                                                        offset={[6,0]}
                                                        count={
                                                            parseInt(props.notificationCount)
                                                        }
                                                    >
                                                        <div className="text-darkable"><Icon name="bell" size={1.4}/></div>
                                                    </Badge>
                                                </a>
                                            </Link>
                                        </Col>
                                        {loggedInUser && router.asPath !== '/create' ? (
                                        <Col flex="auto">
                                            <Tooltip placement="bottom" title='Create a story'>
                                                <div className="text-darkable clickable" onClick={() => {router.push('/create')}}>
                                                    <Icon name='book-medical' size={1.5}/>
                                                </div>
                                            </Tooltip>
                                        </Col>
                                        ) : null }
                                        <Col flex="auto">
                                            <Dropdown 
                                                trigger={['click']}
                                                overlayStyle={{
                                                    position: 'fixed'
                                                }}
                                                overlay={
                                                    <Menu
                                                        style={{ width: 128 }}
                                                        theme={darkMode.value ? 'dark' : 'light'}
                                                    >
                                                        {loggedInUser.admin ? (
                                                        <Menu.Item key="0">
                                                            <Link href='/admin'><a><Icon name="crosshairs" /> Admin</a></Link>
                                                        </Menu.Item>
                                                        ) : null}
                                                        <Menu.Item key="1">
                                                            <Link href='/profile'><a><Icon name="address-card" /> Profile</a></Link>
                                                        </Menu.Item>
                                                        <Menu.Item key="2" onClick={handleChangeViewMode}>
                                                            { darkMode.value ? (
                                                                <span><Icon name="sun" /><span>Light Mode</span></span>
                                                            ) : (
                                                                <span><Icon name="moon" /><span>Dark Mode</span></span>
                                                            )}
                                                        </Menu.Item>
                                                        {/* <Menu.Item key="3">
                                                            <Link href='/liked'><Icon name="heart" /> Liked</Link>
                                                        </Menu.Item>
                                                        <Menu.Item key="4">
                                                            <Link href='/saved'><Icon name="clock" /> Saved</Link>
                                                        </Menu.Item>
                                                        <Menu.Item key="5">
                                                            <Link href='/history'><Icon name="history" /> History</Link>
                                                        </Menu.Item> */}
                                                        <Menu.Item key="6">
                                                            <Link href='/settings'><a><Icon name="cog" /> Settings</a></Link>
                                                        </Menu.Item>
                                                        {/* <Menu.Item key="7">
                                                            <FeedbackModal />
                                                        </Menu.Item> */}
                                                        <Menu.Item key="8">
                                                            <div onClick={handleLogout} className="clickable"><Icon name="sign-out-alt" /> Logout</div>
                                                        </Menu.Item>
                                                    </Menu>
                                                }
                                                style={{zIndex: '1000'}}
                                            >
                                                <div>
                                                    <ProfileAvatar avatarUrl={loggedInUser.profile_picture} size={32} alt={loggedInUser.username} />
                                                </div>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                </Col>
                                
                            </Row>
                        ) : (
                            appLoaded ? (
                                <Row align="middle" style={{height: '100%', margin: 0, textAlign: 'right'}}>
                                    <Col span={12}>
                                        <Link href='/login'>
                                            <Button size="small" style={{marginRight: 10}}>Log In</Button>
                                        </Link>
                                    </Col>
                                    <Col span={12}>
                                        <Link href='/register'>
                                            <Button type="primary" size="small">Sign Up</Button>
                                        </Link>
                                    </Col>
                                </Row>
                            ) : null
                        )}
                </div>
            </div>
            <style jsx>{`
            .menu {
                padding: 0 20px;
                /* border-bottom: solid 1px #e8e8e8; */
                /* overflow: auto; */
                /* box-shadow: 0 0 30px #f3f1f1; */
                width: 100%;
                background: #fff;
            }
            
            .menu__logo {
                width: 150px;
                height: 46px;
                float: left;
            }
            
            .menu__logo a {
                display: inline-block;
                font-size: 20px;
                padding: 10px 20px;
                height: 46px;
            }
            
            .menu__container .ant-menu-item {
                padding: 0px 5px;
            }
            
            .menu__container .ant-menu-submenu-title {
                padding: 10px 20px;
            }
            
            .menu__container .ant-menu-item a,
            .menu__container .ant-menu-submenu-title a {
                padding: 10px 15px;
            }
            
            .menu__container .ant-menu-horizontal {
                border-bottom: none;
            }
            
            .menu__container .menu_left {
                float: left;
            }
            
            .menu__container .menu_rigth {
                float: right;
            }
            
            .menu__mobile-button {
                float: right;
                height: 32px;
                padding: 6px;
                margin-top: 8px;
                display: none !important; /* use of important to overwrite ant-btn */
                background: #3e91f7;
            }
            
            .menu_drawer .ant-drawer-body {
                padding: 0 !important;
            }
            
            /* align header of Drawer with header of page */
            .menu_drawer .ant-drawer-header {
                padding: 14px 24px !important;
            }
            
            .ant-menu {
                background: none;
            }
            
            .menu_container {
                display: flex;
                height: 46px;
            }
            
            .menu_item {
                width: 175px; /* A fixed width as the default */
            }
                
            .menu_item_center { 
                flex-grow: 1; /* Set the middle element to grow and stretch */
            }
                
            .menu_item + .menu_item { 
                margin-left: 2%; 
            }

            .logo {
                height: 46px;
            }
            
            @media (max-width: 767px) {
                .menu__mobile-button {
                    display: inline-block !important;
                }
            
                .menu_left,
                .menu_rigth {
                    display: none;
                }
            
                .menu__logo a {
                    margin-left: -20px;
                }
            
                .menu__container .ant-menu-item,
                .menu__container .ant-menu-submenu-title {
                    padding: 1px 20px;
                }
            
                .menu__logo a {
                    padding: 10px 20px;
                }
            
                .menu_item {
                    width: 125px; /* A fixed width as the default */
                }
            
                .ant-menu-item {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            
                .mobile-hide {
                    display: none;
                }
            }
            `}</style>
        </nav>
    )
}
export default Navbar;