import React, {useContext} from 'react';
import Link from 'next/link'
import {useRouter} from 'next/router'

import UserContext from '../../context/UserContext';
import { baseUrl } from '../../helpers/Api';

const pushRouteLogin = (router) => {
    return router.push({
        pathname: '/login',
        query: {from: encodeURIComponent(router.asPath)}
    });
}

const replaceRouteLogin = (router) => {

    return router.replace({
        pathname: '/login',
        query: {from: encodeURIComponent(router.asPath)}
    });
}

const LinkRouteLogin = ({redirect = null, children}) => {
    const {loggedInUser} = useContext(UserContext);
    const router = useRouter();

    var defaultRedirect = baseUrl(router.asPath);
    if(defaultRedirect === '/login')
        defaultRedirect = '/'

    return (
        loggedInUser ? (
            children
        ) : (
            <Link 
                href={{
                    pathname: '/login',
                    query: {
                        from: encodeURIComponent(redirect || defaultRedirect),
                        message: 'denied'
                    }
                }}
                as={'/login'}
            >
                <a>{children}</a>
            </Link>
        )
    );
}

export {pushRouteLogin, replaceRouteLogin, LinkRouteLogin}