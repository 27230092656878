import React from 'react';

import Footer1 from './components/Footer1';

import {
  Footer10DataSource,
} from './data.source';
// import './style/footer.module.scss';

var isMobile = false;
// enquireScreen((b) => {
//   isMobile = b;
// });

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: false, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    /* 如果不是 dva 2.0 请使用以下代码
    // 实现整屏滚动
    scrollScreen.init({ location: ['Banner3_0', 'Feature0_0', 'Feature6_0', 'Teams0_0', 'Footer1_0'] });
    */
    // 适配手机屏幕;
    // enquireScreen((b) => {
    //   this.setState({ isMobile: !!b });
    // });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    // if (window.location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    // }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    const children = [
      <Footer1
        id="Footer1_0"
        key="Footer1_0"
        dataSource={Footer10DataSource}
        isMobile={this.state.isMobile}
      />,
    ];

    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
