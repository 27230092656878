import React, { useState } from 'react';
import _ from 'lodash';

const AutoHideScrollable = ({onHover=false, onScroll=false, children }) => {
    const [className, setClassName] = useState("hide-scroll");

    return (
        <div className={className} 
            onMouseOver={()=>{
                if(onHover) {
                    setClassName("show-scroll")
                }
            }}
            onMouseLeave={()=>{
                if(onHover) {
                    setClassName("hide-scroll")
                }
            }}
            onScroll={_.throttle(()=>{
                if(onScroll) {
                    setClassName("show-scroll")
                    setTimeout(()=>{setClassName("hide-scroll")}, 1500)
                }
            }, 200)}
        >
            {children}
        </div>
    );
}

export default AutoHideScrollable;