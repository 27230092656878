import React, { useState } from 'react';
import { Modal } from 'antd';

const BasicModal = ({opener, outerClass, outerStyle, innerClass, innerStyle, onOk, onCancel, title, content, footer, defaultOpen}) => {
    const [visible, setVisible] = useState(defaultOpen ? defaultOpen : false);

    const showModal = () => {
        setVisible(true);
    };

    const handleOk = e => {
        if(onOk)
            onOk();
        setVisible(false);
    };

    const handleCancel = e => {
        if(onCancel)
            onCancel();
        setVisible(false);
    };

    const close = () => {
        setVisible(false);
    }

    return (
        <div className={outerClass} style={outerStyle}>
            {
                React.cloneElement(
                    opener,
                    {onClick: showModal}
                )
            }
            <Modal
                title={title}
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={footer}
                className={innerClass} 
                bodyStyle={innerStyle}
            >
                {React.cloneElement(
                    content,
                    { close }
                )}
            </Modal>
        </div>
    );
}

export default BasicModal;