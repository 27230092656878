import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';

import {callApi} from '../../helpers/Api';

const Feedback = (props) => {
    const [text, setText] = useState('');

    const [form] = Form.useForm();
    const inputStyle = {marginBottom: '0'}

    const onChange = (e) => {
        setText(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        callApi('/api/feedback', {
            method: 'POST',
            body: JSON.stringify({
                error: props?.error,
                description: text,
            }),
        })
        .then(json => {
            if(json.id)
                message.success(
                    <p style={{display: 'inline-block'}}>Thanks for your feedback! <span role="img" aria-label="heart">❤️</span></p>
                );
        })

        form.resetFields();
        props.close();
    }

    return (
        <Form form={form} layout='vertical'>
            <p className="text-darkable">Encountered a bug or have an idea for a new feature? Let us know!</p>
            <Form.Item
                name="text"
                label={props.label}
                style={inputStyle}
                rules={[
                    {
                        required: true,
                        message: props.label + ' is required',
                    }
                ]}
            >
                <Input.TextArea
                    placeholder={props.placeholder}
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    onChange={onChange}
                />
            </Form.Item>
            <Form.Item style={inputStyle} >
                <Button block type="primary" htmlType="submit" onClick={handleSubmit}>submit feedback</Button>
            </Form.Item>
        </Form>
    );
}

export default Feedback;