import React from 'react';
import { Avatar} from 'antd';
import {useRouter} from 'next/router'

import Icon from '../icon/Icon';

const ProfileAvatar = (props) => {
    const router = useRouter();

    return (
        <div onClick={(e)=>{
            e.preventDefault()
            if(props.redirect)
                router.push(props.redirect)
        }}>{
            props.avatarUrl ? (
                <Avatar 
                    shape={props.shape ? props.shape : "square"}
                    size={props.size ? props.size : 64} 
                    src={props.avatarUrl}
                    alt={props.alt}
                    style={{cursor:'pointer', backgroundColor: 'white'}}
                />
            ) : (
                <Avatar 
                    shape={props.shape ? props.shape : "square"} 
                    size={props.size ? props.size : 64} 
                    icon={<Icon name="user" color="grey"/>} 
                    style={{cursor:'pointer'}}
                />
            )
        }</div>
    );
}

export default ProfileAvatar;
