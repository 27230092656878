import React, { forwardRef, useImperativeHandle, useState, useEffect, Fragment } from 'react'
import { Slider, message } from 'antd'
import Cropper from 'react-easy-crop'

import getCroppedImg from './CropImage'

const MyCropper = forwardRef((props, ref) => {
    const [imageSrc, setImageSrc] = useState(null)
    const [value, setValue] = useState('')
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [aspect, setAspect] = useState(5/8) // 5:8 aspect ratio
    // const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    useEffect(() => {
        setAspect(props.aspect)
    },[props.aspect])
    
    useImperativeHandle(ref, () => ({
        onDone() {
            setValue('');
            setImageSrc(null);
            setCrop({ x: 0, y: 0 });
            setZoom(1);
        }
    }));

    const onCropChange = crop => {
        setCrop(crop);
    }

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        // TODO: add replace crop image
        // setCroppedAreaPixels(croppedAreaPixels)
        createCropImage(croppedAreaPixels);
    }

    const onZoomChange = zoom => {
        setZoom(zoom);
    }

    const onFileChange = async e => {
        if (e.target.files && e.target.files.length > 0) {
            const limit = props.fileLimit || 5;
            const isWithinLimit = e.target.files[0].size / 1024 / 1024 < limit;
            if (!isWithinLimit) {
                message.error('Picture upload is limited to ' + limit + 'MB or less');
                return false;
            }
            setValue(e.target.value);
            const imageDataUrl = await readFile(e.target.files[0]);
            setImageSrc(imageDataUrl);
            setCrop({ x: 0, y: 0 });
            setZoom(1);
        }
    }

    const createCropImage = async (croppedAreaPixels) => {
        const croppedImage = await getCroppedImg(
          imageSrc,
          croppedAreaPixels
        )
        await props.setImage(croppedImage)
    }

    return (
        <div className="cropper-app">
        <input type="file" onChange={onFileChange} value={value}/>
            {imageSrc && (
            <Fragment>
                <div className="cropper-container">
                    <Cropper
                        image={imageSrc}
                        crop={crop}
                        zoom={zoom}
                        aspect={aspect}
                        onCropChange={onCropChange}
                        onCropComplete={onCropComplete}
                        onZoomChange={onZoomChange}
                        cropShape={props.cropShape ? props.cropShape : 'square'}
                        showGrid={props.showGrid ? props.showGrid : true}
                    />
                </div>
                <div className="cropper-controls">
                    <Slider
                        min={1}
                        max={3}
                        step={0.1}
                        // aria-labelledby="Zoom"
                        onChange={(zoom) => onZoomChange(zoom)}
                        classes={{ container: 'slider' }}
                    />
                    {props.actions}
                </div>
            </Fragment>
            )}
        </div>
    )
})

function readFile(file) {
    return new Promise(resolve => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

export default MyCropper;