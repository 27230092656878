const updateCloudinaryUrl = (url) => {
    try {
        return url.replace("/upload/", "/upload/q_auto,f_auto/");
    } catch (e) {
        return url;
    }
}

export {
    updateCloudinaryUrl,
}