import React, { useState, useEffect, useContext } from 'react';
import Link from 'next/link'
import Image from 'next/image'
import gradient from 'random-gradient'
import Ratio from 'react-ratio';
import uuid from 'uuid';
import classnames from 'classnames';

import UserContext from '../../context/UserContext';
import AppContext from '../../context/AppContext';
import Icon from '../icon/Icon';
import CropperModal from '../cropper/Modal'
import {Textfit} from '@kyo_ago/react-textfit';
import {storyUrl} from '../../helpers/Api';
import {updateCloudinaryUrl} from '../../helpers/Cloudinary';

const Cover = (props) => {
    const {loggedInUser} = useContext(UserContext);
    const {siderLoaded} = useContext(AppContext);
    const [imageSrc, setImageSrc] = useState(updateCloudinaryUrl(props.imageSrc));
    const [isAdult, setIsAdult] = useState(loggedInUser?.is_adult);
    const [isHovering, setIsHovering] = useState(false);

    useEffect(() => {
        if(loggedInUser)
            setIsAdult(loggedInUser.is_adult)
    }, [loggedInUser])

    useEffect(() => {
        setImageSrc(updateCloudinaryUrl(props.imageSrc))
    }, [props.imageSrc])

    var cardCover = null;
    if( imageSrc && (!props.isAdultContent || isAdult)) {
        cardCover = (
            <Image 
                width={500}
                height={800}
                className="editable-image"
                style={{width: '100%', height: 'auto'}}
                alt="example" 
                key={imageSrc}
                src={imageSrc} 
            />
        )
    } else {
        cardCover = (
            <Ratio ratio={ 5 / 8 } style={{background: props.skeleton ? 'silver' : gradient(props.storyUuid)}}>
                {!siderLoaded ? null : (
                    <Textfit style={{
                        width: '100%',
                        height: '100%',
                        padding: '10px',
                        color: '#fff', 
                        textAlign: 'center', 
                        margin: 'auto',
                        position: 'absolute',
                    }}>
                        {props.title}
                    </Textfit>
                )}
            </Ratio>
        )
    }

    return (
        props.editing ? (
            <CropperModal 
                title='Upload Story Cover'
                url={'/api/upload/storyCover/' + props.storyUuid}
                opener={
                    <div className="editable-image-wrapper">
                        <div className="editable-image-text">
                            <Icon name='cog' size={4}/>
                        </div>
                        {cardCover}
                    </div>
                }
                updatePhoto={image => {setImageSrc(updateCloudinaryUrl(image))}}
                aspect={5/8}
            />
        ) : (
            (props.linkCovertoStory) ? (
                <Link href="/read/[storyUuid]/[storyVersion]/[title]" as={'/read/' + props.storyUuid + '/' + props.storyVersion + '/' + storyUrl(props.title)}>
                    <a>
                        {cardCover} 
                    </a>
                </Link>
            ) : (
                <div 
                    className="cover-container" 
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                >
                    {cardCover}
                    <div className={classnames('cover-text', {
                        hide: isHovering,
                    })}>
                        <div className="cover-text-bottom-left">{props.bottomLeftText}</div>
                        <div className="cover-text-top-left">{props.topLeftText}</div>
                        <div className="cover-text-top-right">{props.topRightText}</div>
                        <div className="cover-text-bottom-right">{props.bottomRightText}</div>
                        <div className="cover-text-centered">{props.centeredText}</div>
                    </div>
                </div>
            )
        )
    );
}

export default Cover