import React, {useRef, useState, useContext} from 'react';
import { Spin, message } from 'antd';

import UserContext from '../../context/UserContext';
import BasicModal from '../common/BasicModal';
import Cropper from '.';
import {callApi} from '../../helpers/Api';

const CropperModal = (props) => {
    const {loggedInUser} = useContext(UserContext);
    const [image, setImage] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const cropperRef = useRef();

    return (
        isUploading ? (
            <Spin tip="Uploading"></Spin>
        ) : (
            <BasicModal 
                title={props.title}
                content={
                    <Cropper
                        ref={cropperRef} 
                        setImage={setImage}
                        aspect={props.aspect}
                    />
                }
                opener={props.opener}
                outerClass={props.outerClass ? props.outerClass : null}
                onCancel={
                    async () => {
                        cropperRef.current.onDone()
                    }
                }
                onOk={
                    async () => {
                        setIsUploading(true);
                        let blob = await fetch(image).then(r => r.blob());
                        var formData = new FormData();
                        const section = props.url.split(/[/ ]+/).pop();
                        formData.append("image", blob, section+"-"+loggedInUser.id+".png");

                        callApi(props.url, {
                            method: 'POST',
                            body: formData
                        },{})
                        .then(res => {
                            if(!res.success) {
                                message.error(res?.data?.err?.message || res?.message || "something went wrong while processing your request")
                            } else {
                                message.success('Cover art updated!')
                                props.updatePhoto(res.data.image)
                            }
                            setIsUploading(false);
                        })
                        .catch(err => console.log(err))
                    }
                }
            />
        )
    );
}

export default CropperModal;