import React, { useContext, useRef, useEffect } from 'react';
import { Layout } from 'antd';
import Head from 'next/head'
import {useRouter} from 'next/router'

import Home from './Home';
import Navbar from './navbar';
import MySider from './sider';
import FeedbackModal from './forms/FeedbackModal';
import Icon from './icon/Icon';
import Footer from './anim/Footer';
import UserContext from '../context/UserContext';

const CustomLayout = ({ 
	children, 
	title="fablement", 
	description="Read and create interactive stories with no programming experience for the whole world to see. Choose your own adventure has never been this easy!", 
	keywords="fablement, interactive, fiction, story, book", 
	image="https://fablement.com/android-chrome-512x512.png", 
	language='en', 
	showHomeSkeleton=false,
	showSiderExpanded=false,
	expandSiderInstantly=false,
	showFooter=true,
	...rest 
}) => {
	const {notificationCount} = useContext(UserContext);
	const router = useRouter();
	const siderRef = useRef();

	useEffect(()=>{
		setTimeout(() => {
			let link;
			if(notificationCount) {
				link = '/favicon-notif-32x32.png'
			} else {
				link = '/favicon-32x32.png'
			}
			let $title = document.querySelector('title')
			let $favicon = document.querySelector('link[rel="icon"]')
			if($title !== null && notificationCount) {
				document.title = "(" + notificationCount +") " + document.title
			}
			// If a <link rel="icon"> element already exists, change its href to the given link.
			if ($favicon !== null) {
				$favicon.href = link
			}
		}, 0)
	}, [notificationCount])

	return (
		<div>
			<Head>
				<meta name="viewport" content="initial-scale=1.0, width=device-width" />
				<meta name="theme-color" content="#118b92" />
				{/* for pwa */}
				{/* <link rel="manifest" href="/static/manifest/manifest.json" /> */}
				{process.env['NEXT_PUBLIC_DOMAIN_NAME'] === 'creatorport.com' ? (
					<meta name="robots" content="noindex" />
				) : null}

				<title>{title}</title>
				<meta name="description" content={description} />
				<meta name="keywords" content={keywords} />
				<meta name="author" content="fablement.com" />

				<meta property="og:site_name" content="fablement.com" />
				<meta property="og:title" content={notificationCount ? "(" + notificationCount + ") " + title : title} />
				<meta property="og:description" content={description || "no description"} />
				<meta property="og:url" content={`https://fablement.com${router.asPath}`} />
				<meta property="og:image" content={image} />
				<meta property="og:type" content="website" />

				<meta property="fb:app_id" content="708159673008590" />

				<meta name="twitter:title" content={title} />
				<meta name="twitter:description" content={description} />
				<meta name="twitter:image" content={image} />
				<meta name="twitter:card" content="summary_large_image" />

				<link rel="apple-touch-icon" sizes="180x180" href={'/apple-touch-icon.png'} />
				<link rel="icon" type="image/png" href={notificationCount ? '/favicon-notif-16x16.png' : '/favicon-16x16.png'} sizes="16x16" />
				<link rel="icon" type="image/png" href={notificationCount ? '/favicon-notif-32x32.png' : '/favicon-32x32.png'} sizes="32x32" />
				<link rel="manifest" href="/site.webmanifest" />
				<link rel="mask-icon" href={'/safari-pinned-tab.svg'} color="#ffffff"/>
				<meta name="msapplication-TileColor" content="#b91d47" />
				<meta name="theme-color" content="#ac3454" />
			</Head>
			<div className="rde-main">
				<div className="clickable" style={{
						position: 'fixed',
						bottom: '30px', 
						right: '15px',
						zIndex: '5'
				}}>
					<FeedbackModal 
						opener={
						<Icon 
							name="comment-alt" 
							size={2.5} 
							color="#00CED1"
							innerIcon="star"
							innerColor="yellow"
							innerStyle={{top: '-10px'}}
						/>}
					/>
				</div>
				<div className="rde-title">
					<Navbar 
						toggleCollapseSider={async () => {
							siderRef.current.toggleCollapseSider()
						}}
						notificationCount={notificationCount}
					/> 
				</div>
				<div className="rde-content heavy-darkable">
					<Layout>
						<MySider 
							ref={siderRef}
							showSiderExpanded={showSiderExpanded}
							expandSiderInstantly={expandSiderInstantly}
						/>
						<Layout className="main-content">
							<Layout.Content>
								{children}
							</Layout.Content>
							{ showFooter ? (
								<Footer />
							) : null}
						</Layout>
					</Layout>
				</div>
			</div>
		</div>
	)
}

export default CustomLayout;
