import React from 'react'

import BasicModal from '../common/BasicModal';
import Icon from '../icon/Icon';
import Feedback from './Feedback';

const FeedbackModal = (props) => {
    return (
        <BasicModal 
            title="Submit Feedback!"
            content={
                <Feedback />
            }
            opener={
                props.opener ? props.opener : <div><Icon name="comment-alt"/> Feedback</div>
            }
            outerStyle={{display: 'inline'}}
            innerClass="medium-darkable-modal"
            defaultOpen={props.defaultOpen ? props.defaultOpen : false}
            footer={false}
        />
    );
}

export default FeedbackModal;