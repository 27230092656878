import React, { useState, useEffect, useContext } from 'react';
import Link from 'next/link'

import {distanceInWordsToNow} from 'date-fns';
import { Card, Skeleton } from 'antd';

import { cleanNumber, wordCountToMin, wordCountDiffMin } from '../../helpers/NumberFormat';
import { callApi, storyUrl } from '../../helpers/Api';
import Cover from './Cover';
import NsfwTag from '../common/NsfwTag';
import EnabledButton from '../common/EnabledButton';
import ProfileAvatar from '../common/ProfileAvatar';
import UserContext from '../../context/UserContext';

const StoryCard = ({story, own, skeleton, showActions=true}) => {
    const {loggedInUser} = useContext(UserContext);
    const [liked, setLiked] = useState(false);
    const [saved, setSaved] = useState(false);

    useEffect(() => {
        if(loggedInUser && story) {
            callApi('/api/own/story_like_save/' + story.uuid)
            .then(values => {
                if(values) {
                    setLiked(values[0]?.liked)
                    setSaved(values[0]?.saved)
                }
            })
        }
    }, [loggedInUser, story])

    return (
        skeleton ? (
            <Card 
                className="medium-darkable"
                hoverable
                cover={
                    <Cover
                        skeleton
                    />
                }
                actions={[
                    <EnabledButton 
                        showCount
                        showDivider
                        enabledIcon="heart"
                        getInitialEnabled={()=>{}}
                        getInitialCount={()=>{}}
                    />,
                    <EnabledButton 
                        showCount
                        showDivider
                        enabledIcon="bookmark"
                        getInitialEnabled={()=>{}}
                        getInitialCount={()=>{}}
                    />,
                ]}
                bodyStyle={{paddingBottom:'0px', height: '170px'}}
            >
                <Card.Meta
                    title={<Skeleton active />}
                />
            </Card>
        ) : (
            <Link href="/book/[...story]" as={'/book/' + story.uuid + '/' + storyUrl(story.title) + (own ? '/admin' : '')}>
                <div className="book-card">
                    <Card 
                        className="medium-darkable"
                        hoverable
                        cover={
                            <Link href="/book/[...story]" as={'/book/' + story.uuid + '/' + storyUrl(story.title) + (own ? '/admin' : '')}>
                                <a>
                                    <Cover 
                                        // bottomLeftText={'test'}
                                        // topLeftText={'test'}
                                        topRightText={
                                            <small>{story.read_date ? <p style={{margin:'0'}}>last read {distanceInWordsToNow(story.read_date)} ago</p> : null}</small>
                                        }
                                        bottomRightText={
                                            <small>
                                                {/* {story.max_node_depth + ' pages deep' }<br/>  */}
                                                {/* {story.endings.length + ' endings'}<br />  */}
                                                {(wordCountDiffMin(story.max_word_count, story.min_word_count) ? 
                                                    '~' + wordCountToMin(story.max_word_count) + ' mins'
                                                : 
                                                    '~' + wordCountToMin(story.min_word_count)
                                                    + ' - ' +
                                                    wordCountToMin(story.max_word_count) + ' mins'
                                                ) + ' read'
                                            }</small>
                                        }
                                        isAdultContent={story.is_nsfw}
                                        storyUuid={story.uuid} 
                                        imageSrc={story.cover} 
                                        title={story.title} 
                                        author={story.username}
                                    />
                                </a>
                            </Link>
                        }
                        actions={ showActions ? [
                            <EnabledButton
                                key={story.uuid} 
                                showCount
                                showDivider
                                enabledIcon="heart"
                                enabledIconColor="red"
                                tooltipTitle="loved it"
                                getInitialCount={(callback)=>{callback(story.story_like_count)}}
                                enabled={liked}
                                handleEnabled={(newEnabled, callback)=>{
                                    callApi('/api/own/upsert/story_like', {
                                        method: 'POST',
                                        body: JSON.stringify({story_id: story.story_id, liked: newEnabled})
                                    })
                                    .then(callback(newEnabled))
                                    .catch(err => {console.log(err)});
                                }}
                            />,
                            <EnabledButton 
                                key={story.uuid} 
                                showCount
                                showDivider
                                enabledIcon="bookmark"
                                enabledIconColor="cyan"
                                tooltipTitle="save it"
                                getInitialCount={(callback)=>{callback(story.story_save_count)}}
                                enabled={saved}
                                handleEnabled={(newEnabled, callback)=>{
                                    callApi('/api/own/upsert/story_save', {
                                        method: 'POST',
                                        body: JSON.stringify({story_id: story.story_id, saved: newEnabled})
                                    })
                                    .then(callback(newEnabled))
                                    .catch(err => {console.log(err)});
                                }}
                            />,
                        ] : [<div></div>]}
                        bodyStyle={{paddingBottom:'0px', height: '150px'}}
                    >
                        <Card.Meta
                            avatar={
                                <ProfileAvatar 
                                    avatarUrl={story.profile_picture}
                                    size={32}
                                    redirect={'/profile/' + story.username}
                                />
                            }
                            title={<span>{story.is_nsfw ? <NsfwTag /> : null}{story.title}</span>}
                            description={
                                <p className="light-text story-card-description-text">{story.description}</p>
                            }
                        />
                        <p style={{zIndex: '10'}}>
                            <Link href="/profile/[username]" as={'/profile/' + story.username}>
                                <a>{story.username}</a>
                            </Link> 
                            <span> | {cleanNumber(story.story_read_count + story.story_read_anon_count)} reads | {distanceInWordsToNow(story.publish_date || story.created_at)} ago</span>
                        </p>
                    </Card>
                </div>
            </Link>
        )
    )
}

export default StoryCard;